import { StyleProp, ViewStyle } from "react-native";
import useDevice from "src/hooks/useDevice";
import { textSpaceBetween } from "src/screens/(informational)/InformationalHome/styles";
import { paddingXXXL } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import DownloadOnAppStore from "../../DownloadOnAppStore";
import TextSectionContainer from "../../TextSectionContainer";

const TextSection = ({
	style,
	header,
	body,
}: {
	style?: StyleProp<ViewStyle>;
	header: string[];
	body: string;
}) => {
	const { device } = useDevice();

	return (
		<TextSectionContainer style={[{ alignItems: "flex-start", width: "auto" }, style]}>
			<Text
				h2
				style={{
					textAlign: "left",
				}}
			>
				{header.length > 0 && header[0]}
			</Text>
			<Text
				h2
				bold
				style={{
					textAlign: "left",
				}}
			>
				{header.length > 1 && header[1]}
			</Text>
			<Text
				h4
				color="TextLight"
				style={{
					marginTop: textSpaceBetween[device],
					textAlign: device === "mobile" ? "center" : "left",
				}}
			>
				{body}
			</Text>
			<DownloadOnAppStore
				style={{
					marginTop: paddingXXXL,
				}}
			/>
		</TextSectionContainer>
	);
};
export default TextSection;
