import { StyleProp, View, ViewStyle } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Link } from "solito/link";
import { APP_STORE_URL } from "src/constants/appStore";
import useDevice from "src/hooks/useDevice";
import { useModal } from "src/modals";
import InformationalModal from "src/modals/configs/InformationalModal";
import { TransitionConfig } from "src/screens/(informational)/constants";
import { MDiv } from "src/swsh-native";
import lazy from "src/swsh-native/lazy";

const IconDownloadOnAppStore = lazy(() => import("src/icons/IconDownloadOnAppStore"), {
	loading: () => null,
});

// Pulled from DownloadOnAppStore.svg
const aspectRatio = 119.66407 / 40;
const DownloadOnAppStore = ({ style }: { style?: StyleProp<ViewStyle> }) => {
	const { device } = useDevice();
	const width = device === "mobile" ? 125 : 170;
	const { presentModal } = useModal<InformationalModal>();

	const Content = (
		<MDiv
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			transition={TransitionConfig}
			style={{
				width: width,
				aspectRatio,
				justifyContent: "center",
				overflow: "hidden",
			}}
		>
			<IconDownloadOnAppStore
				// Fix for a weird clipping issue
				size={width - 4}
				style={{
					position: "absolute",
				}}
			/>
		</MDiv>
	);

	return (
		<View
			style={[
				{
					width: width,
					aspectRatio,
				},
				style,
			]}
		>
			{device === "mobile" ? (
				<Link href={APP_STORE_URL} target="_blank">
					{Content}
				</Link>
			) : (
				<TouchableOpacity
					onPress={() => {
						presentModal("ScanToDownload");
					}}
				>
					{Content}
				</TouchableOpacity>
			)}
		</View>
	);
};
export default DownloadOnAppStore;
